<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2 style="margin: 20px 0 0 0">GRADING</h2>
        Grade a test.
      </div>
    </div>
    <hr />
    <el-form label-width="80px">
      <!-- <el-form-item label="Type"
        ><b>{{ form.category }}</b></el-form-item
      > -->
      <el-form-item label="Test"
        ><b>{{ form.title }}</b></el-form-item
      >
      <el-form-item
        label="Keyboard:"
        class="keyboard"
        v-if="form.category === 'Sat' || form.category === 'Act'"
      >
        <el-radio-group v-model="keyboard" class="row">
          <div class="col-md-6">
            <el-radio label="2">
              <img
                :class="keyboard === '2' ? 'keyboard_on' : ''"
                :src="keyboard_url[1]"
              />
            </el-radio>
          </div>
          <div class="col-md-6">
            <el-radio label="1">
              <img
                :class="keyboard === '1' ? 'keyboard_on' : ''"
                :src="keyboard_url[0]"
              />
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="text-center">
      <el-button
        type="success"
        @click="startGrading"
        :disabled="form.test === ''"
      >
        Next
      </el-button>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Tests from "@/apis/tests.js";

export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      form: {
        exam_id: 0,
        category: "",
        title: "",
        user_exam_id: 0
      },
      keyboard: "2"
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    userExamId() {
      return this.$route.query.user_exam_id;
    },
    keyboard_url() {
      let url = [];
      if (this.form.category === "Sat") {
        url[0] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type2.jpg";
        url[1] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type1.jpg";
      }
      if (this.form.category === "Act") {
        url[0] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type2.jpg";
        url[1] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type1.jpg";
      }
      return url;
    }
  },
  watch: {},

  mounted() {
    this.getUserExamInfo();
  },

  methods: {
    async getUserExamInfo() {
      const result = await Tests.getUserExamInfo(this.userExamId);
      this.form = result;
    },
    async startGrading() {
      if (this.form.category == "Sat" || this.form.category == "Act") {
        let url_name = "Grading" + this.form.category.toUpperCase();
        this.routerPush({
          name: url_name,
          query: {
            keyboard: this.keyboard,
            exam_id: this.form.exam_id,
            user_id: this.form.user_id,
            user_exam_id: this.form.user_exam_id
          }
        });
      } else if (this.form.category == "Compose Practice") {
        this.getTeacherClassExams();
      } else if (this.form.category == "Toefl") {
        this.getTeacherClassExams();
      }
    },
    async getTeacherClassExams() {
      const res = await Tests.getTeacherClassExams(this.form.exam_id);
      if (res.toefl_exam) {
        this.routerPush({
          name: "SetTest",
          query: {
            title: res.toefl_exam.title,
            testType: "mock",
            version: res.toefl_exam.type
          }
        });
      }
      if (res.compose_practice_id) {
        this.$router.push({
          name: "FullPractice",
          params: {
            fullPracticeId: res.compose_practice_id
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.keyboard img {
  width: 100%;
  opacity: 0.2;
}
.keyboard >>> .el-radio__label {
  padding: 0;
}
.keyboard >>> .el-radio__input {
  display: none;
}
img.keyboard_on {
  opacity: 1;
}
</style>
